import {
  allIncomeProducts, allSoldProducts, allResidualProducts, notSoldProducts, goodClients, priceProductsReport,
} from '@/api/reports'

export const actions = {
  allIncomeProducts({ commit }, query) {
    return new Promise((resolve, reject) => {
      allIncomeProducts(query).then(res => {
        commit('SET_ITEMS', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  allSoldProducts({ commit }, query) {
    return new Promise((resolve, reject) => {
      allSoldProducts(query).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  allResidualProducts({ commit }, query) {
    return new Promise((resolve, reject) => {
      allResidualProducts(query).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  notSoldProducts({ commit }, query) {
    return new Promise((resolve, reject) => {
      notSoldProducts(query).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  goodClients({ commit }, query) {
    return new Promise((resolve, reject) => {
      goodClients(query).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  priceProductsReport({ commit }, query) {
    return new Promise((resolve, reject) => {
      priceProductsReport(query).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
}
