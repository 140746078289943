<template>
  <div></div>
</template>

<script>
import { onMessage, getToken } from 'firebase/messaging'
import { showToast } from '@/utils/toastNotification'
import messaging from '../plugins/firebase'

export default {
  name: 'Notification',
  async mounted() {
    setTimeout(() => {
      getToken(messaging, { vapidKey: 'BKf2D8EAv1E8rTvNbzuccpfW8Tjf3fObWEvV9n46IF7KV3ZYIdM63ybEOM_3WzUQBy81wPhHvngqgzU61jrtsBM' }).then(currentToken => {
        if (currentToken) {
          this.$store.dispatch('notification/saveToken', currentToken)
          onMessage(messaging, payload => {
            const body = JSON.parse(payload.notification.body)
            console.log(body)
            console.log(`Уведомление принято:${payload.notification.body}`)
            if (body.images_confirmation_status && !this.isImagesChecker) {
              showToast('warning', payload, 'BellIcon')
            } else {
              showToast('warning', payload, 'BellIcon')
            }
          })
        } else {
          console.log('Show permission request UI')
        }
      }).catch(err => {
        console.log('An error occurred while retrieving token. ', err)
      })
    }, 1000)
  },
}
</script>

<style scoped>

</style>
