import vue from 'vue'
import ToastificationNotification from '@core/components/toastification/ToastificationNotification'

export function showToast(type, message, icon) {
  if (!icon) {
    switch (type) {
      case 'success': icon = 'CheckCircleIcon'; break
      case 'danger': icon = 'XIcon'; break
      case 'warning': icon = 'AlertTriangleIcon'; break
    }
  }
  vue.$toast({
    component: ToastificationNotification,
    timeout: 10000,
    props: {
      timeout: 10000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      title: message.notification.title,
      text: JSON.parse(message.notification.body),
      icon,
      variant: type,
    },
  })
}
