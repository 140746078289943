import { initializeApp } from 'firebase/app'
import { getMessaging, isSupported } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyB7VLXNMxZ1t5YxTS3qFxI8tFshHOsZsPQ',
  authDomain: 'wamart-493bb.firebaseapp.com',
  projectId: 'wamart-493bb',
  storageBucket: 'wamart-493bb.appspot.com',
  messagingSenderId: '860284476971',
  appId: '1:860284476971:web:5043bbe04d137f5cb0546e',
  measurementId: 'G-3BEZNVQ6G8',
}
const app = initializeApp(firebaseConfig)
const isSupport = true
const messaging = isSupport ? getMessaging(app) : null
export default messaging
