import request from '@/utils/request'

const slug = '/reports'

export function allIncomeProducts(params) {
  return request({
    url: `${slug}/by-all-income-products`,
    method: 'get',
    params,
  })
}

export function allSoldProducts(params) {
  return request({
    url: `${slug}/by-all-sold-products`,
    method: 'get',
    params,
  })
}
export function priceProductsReport(params) {
  return request({
    url: '/warehouses/get-visible-party-products',
    method: 'get',
    params,
  })
}
export function allResidualProducts(params) {
  return request({
    url: `${slug}/by-all-residual-products`,
    method: 'get',
    params,
  })
}

export function notSoldProducts(params) {
  return request({
    url: `${slug}/by-not-sold-products`,
    method: 'get',
    params,
  })
}
export function goodClients(params) {
  return request({
    url: `${slug}/good-clients`,
    method: 'get',
    params,
  })
}

export function show(id) {
  return request({
    url: `${slug}/${id}`,
    method: 'get',
  })
}
